(function () {
  'use strict';

  angular
      .module('neo.home.persons.person')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('home.persons.person', {
          url: '?{team}&{license}',
          abstract: true,
          templateUrl: 'home/persons/person/person.tpl.html',
          controller: 'PersonCtrl',
          controllerAs: 'vm',
          resolve: {
            licenseTeamPerson: function(LicenseTeamPerson, $stateParams) {
              return LicenseTeamPerson.query({
                licenseId: $stateParams.license,
                teamId: $stateParams.team
              }).$promise;
            }
          }
        });
  }
}());
